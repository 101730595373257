import { createAction } from '@reduxjs/toolkit';
import {
  IMA,
  IMASigner,
  createIMA,
  updateIMA as updateIMAInfo,
  fetchIMAListWithQID,
  updateIMASigner as updateIMASignerInfo,
} from '@qwealth/qdata';

import { errorHandler } from 'services/axiosService';
import { AppThunkDispatch } from '../store';

const SET_ACTIVE_IMA = 'SET_ACTIVE_IMA';
const UPDATE_IMA = 'UPDATE_IMA';
const SET_IMA_LIST = 'SET_IMA_LIST';
const UPDATE_IMA_SIGNER = 'UPDATE_IMA_SIGNER';

export const setActiveIma = createAction<IMA>(SET_ACTIVE_IMA);
export const updateIma = createAction<IMA>(UPDATE_IMA);
export const setImaList = createAction<IMA[]>(SET_IMA_LIST);
export const updateImaSigner = createAction<IMASigner>(UPDATE_IMA_SIGNER);

export const createNewIMA = (payload: IMA) => async (dispatch: AppThunkDispatch): Promise<void> => {
  try {
    const ima = await createIMA(payload);
    dispatch(setActiveIma(ima));
  } catch (e) {
    await errorHandler(dispatch, 'Creating IMA failed')(e);
  }
};

export const updateIMA = (payload: IMA) => async (dispatch: AppThunkDispatch): Promise<void> => {
  try {
    const ima = await updateIMAInfo(payload) as IMA;
    dispatch(updateIma(ima));
  } catch (e) {
    await errorHandler(dispatch, 'Updating IMA failed')(e);
  }
};

export const fetchIMAList = (QID: string) => async (dispatch: AppThunkDispatch): Promise<void> => {
  try {
    const imas = await fetchIMAListWithQID(QID);
    dispatch(setImaList(imas));
  } catch (e) {
    await errorHandler(dispatch, 'Fetching IMAs failed')(e);
  }
};

export const updateIMASigner = (payload: IMASigner) => async (dispatch: AppThunkDispatch): Promise<void> => {
  try {
    const imaSigner = await updateIMASignerInfo(payload) as IMASigner;
    dispatch(updateImaSigner(imaSigner));
  } catch (e) {
    await errorHandler(dispatch, 'Updating IMASigner failed')(e);
  }
};
