import { Dispatch } from 'redux';
import axios from 'axios';
import { HouseholdMetadata } from '@qwealth/qdata';
import {
  errorHandler,
  REACT_APP_QWEALTH_QC_API,
  REACT_APP_QWEALTH_API,
} from '../../services/axiosService';
import { putHouseholdMetadata } from './householdMetadata';
import { AppThunkDispatch } from '../store';
import { updateHouseholdRequest } from '../data-layer/household';

export const RESET_HOUSEHOLD_STATE = 'RESET_HOUSEHOLD_STATE';
export const resetHouseholdState = () => ({ type: RESET_HOUSEHOLD_STATE });

export const updateHouseholdCache = (householdId: string) => (dispatch: Dispatch): Promise<void> =>
  axios
    .put(`${REACT_APP_QWEALTH_QC_API}/qcentral/households/${householdId}`)
    .catch(errorHandler(dispatch, 'Failed to update household cache'));

export const updateHouseholdDetails = (household: HouseholdMetadata) => (dispatch: Dispatch): Promise<void> =>
  updateHouseholdRequest({
    name: household.name,
    iaCode: household.iaCode,
    insuranceCode: household.insuranceCode,
    QID: household.QID,
    status: household.status
  })
    .then(() => {
      dispatch(putHouseholdMetadata(household));
      // @ts-ignore
      dispatch(updateHouseholdCache(household.QID));
    })
    .catch(errorHandler(dispatch));

export const deleteHousehold = (householdId: string) => (dispatch: AppThunkDispatch): Promise<void> =>
  axios
    .delete(`${REACT_APP_QWEALTH_API}/households/${householdId}`)
    .catch(errorHandler(dispatch, 'Failed to delete household. Please contact support.'));
