import { Dispatch } from 'redux';
import { createAction } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import {
  IPS,
  updateIPS,
  createIPS,
  fetchContactKyc,
  createRTQ,
  fetchRTQListWithQIDs,
  RawRTQ,
  RTQ,
  saveRTQ,
  fetchRTQWithContactQID
} from '@qwealth/qdata';
import { ENTITY_QUIZ_VERSION, QUIZ_VERSION } from '@qwealth/rtq';
import { errorHandler } from 'services/axiosService';
import { AppThunkDispatch, RootState } from '../store';
import { portfolioManagerApprovalApi } from '../data-layer/rtq';
import { fetchProposalPdf, fetchProposalMandatesAndModels } from 'data/data-layer/rtq';
import { Proposal, ProposalResponse } from 'data/models/RtqProposal';

const ADD_RTQ = 'ADD_RTQ';
const LOAD_RTQ = 'LOAD_RTQ';
const ADD_IPS_TO_RTQ = 'ADD_IPS_TO_RTQ';
const UPDATE_KYC_RTQ = 'UPDATE_KYC_RTQ';

export const addRtq = createAction<RTQ>(ADD_RTQ);
export const loadHouseholdRtqs = createAction<Record<string, RTQ[]>>(LOAD_RTQ);
export const addIpsToRtq = createAction<IPS>(ADD_IPS_TO_RTQ);
export const updateKycRtq = createAction<RTQ>(UPDATE_KYC_RTQ);

let loading = false;
/**
 * @deprecated legacy pattern that's very hairy for household vs individual RTQs
 */
export const loadHouseholdRTQ = (householdId: string, memberIds: Array<string>, reload?: boolean) => async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
  const existingQIDs = Object.keys(getState().RTQ);
  if ((loading || [householdId, ...memberIds].every(qid => existingQIDs.includes(qid))) && !reload) {
    return Promise.resolve();
  }
  const fallbackState = {
    [householdId]: [],
    ...memberIds.reduce((map, qid) => {
      if (qid) {
        map[qid] = [];
      }
      return map
    }, {})
  };
  try {
    loading = true;
    const rtqMap = await fetchRTQListWithQIDs(householdId, memberIds);
    dispatch(loadHouseholdRtqs({ ...fallbackState, ...rtqMap }));
  } catch (e) {
    dispatch(loadHouseholdRtqs(fallbackState));
    await errorHandler(dispatch, 'RTQ load failed')(e);
  } finally {
    loading = false;
  }
};

export const loadLegalEntityRTQs = (qid: string, reload?: boolean) => async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
  const existingQIDs = Object.keys(getState().RTQ);
  if (loading || (existingQIDs.includes(qid) && !reload)) {
    return Promise.resolve();
  }
  return fetchRTQWithContactQID(qid)
    .then((rtqs) => {
      let rtqArray: RTQ[] = [];
      if (rtqs) {
        if (Array.isArray(rtqs)) {
          rtqArray = rtqs;
        } else {
          rtqArray = [rtqs];
        }
      }
      dispatch(loadHouseholdRtqs({ [qid]: rtqArray }));
    })
    .finally(() => {
      loading = false;
    });
}

/**
 * @deprecated the API will handle rtq initialization moving forward
 */
export const createNewRTQ = (QID: string, householdId: string) =>
  async (dispatch: AppThunkDispatch): Promise<boolean> => {
    try {
      // Fetch KYCID for the QID
      const kyc: any = await fetchContactKyc(QID);
      const kycID = kyc.id;

      const versionNumber = kyc.rtqLevel === 'Corporate' ? ENTITY_QUIZ_VERSION : QUIZ_VERSION
      const rtq = await createRTQ(QID, householdId, kycID, versionNumber);
      dispatch(addRtq(rtq as RTQ));
      return Promise.resolve(true);
    } catch (e) {
      await errorHandler(dispatch, 'Create new RTQ failed')(e);
      return Promise.resolve(false);
    }
  };

export const updateRTQ = (rtq: Partial<RTQ>, key?: string) =>
  async (dispatch: AppThunkDispatch): Promise<void> => {
    try {
      const fieldsToOmit = ['ips', 'rtqResponses'];
      const rtqOmit = {
        ...omit(rtq, fieldsToOmit),
        accounts: rtq.accounts?.join(),
      } as RawRTQ;

      // clean up nulls
      for (const prop in rtqOmit) {
        if (rtqOmit[prop] == null) {
          delete rtqOmit[prop];
        }
      }

      const updatedRtq = await saveRTQ(rtqOmit);
      fieldsToOmit.forEach(field => {
        const val = rtq[field];
        if (val) {
          updatedRtq[field] = val;
        }
      });
      if (key) {
        dispatch(loadHouseholdRtqs({ [key]: [updatedRtq] }));
      } else {
        dispatch(addRtq(updatedRtq));
      }
      dispatch(updateKycRtq(updatedRtq));
    } catch (e) {
      await errorHandler(dispatch)(e);
    }
  };

export const saveIPS = (ips: IPS) => async (dispatch: AppThunkDispatch): Promise<IPS | undefined> => {
  try {
    const savedIPS = (ips.id ? await updateIPS(ips) : await createIPS(ips)) as IPS;
    dispatch(addIpsToRtq(savedIPS));

    return savedIPS;
  } catch (e) {
    await errorHandler(dispatch, 'Saving IPS failed')(e);
  }
};

export const portfolioManagerApproval = (rtqId: number, approvalFlag: boolean) => async (dispatch: AppThunkDispatch): Promise<void> => {
  return await portfolioManagerApprovalApi(rtqId, approvalFlag)
    .catch(errorHandler(dispatch, 'Failed to update portfolio manager approval'));
}

export const getProposalMandatesAndModels =
  () =>
  async (dispatch: Dispatch): Promise<ProposalResponse | null> => {
    try {
      return await fetchProposalMandatesAndModels();
    } catch (error) {
      errorHandler(dispatch, 'Failed fetching mandates and models. Please contact support.')(error);
      return null;
    }
  };

export const generateProposalPdf =
  (proposal: Proposal) =>
  async (dispatch: Dispatch): Promise<Blob | null> => {
    try {
      return await fetchProposalPdf(proposal);
    } catch (error) {
      const msg = 'Failed generating KYC Proposal. Please contact support and provide form info.'
      errorHandler(dispatch, msg)(error);
      return null;
    }
  };
