import { omit } from 'lodash';
import { ILegalEntityDTO } from '@qwealth/qdata';
import { CreateEntityForm } from 'data/models/CreateEntityForm';

export const cleanupEntityPayload = (values: CreateEntityForm): ILegalEntityDTO => {
    const omittedPaths = ['selectedHousehold'];
    if (!values.controllingParty) {
        omittedPaths.push('controllingParty');
        omittedPaths.push('controllingPartyAuthorizedType');
    }
    return {
        ...omit(values, omittedPaths),
        name: values.name!,
        entityType: values.entityType!,
        partnerID: values.partnerID!,
        ownership: [],
        BIN: values.BIN,
        nbinBankAccountDetails: [],
        regulatory: []
    };
}