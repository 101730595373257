import { IMA } from '@qwealth/qdata';

import {
  setActiveIma,
  updateIma,
  setImaList,
  updateImaSigner
} from '../actions/IMA';
import { createReducer } from '@reduxjs/toolkit';

export default createReducer([] as IMA[], (builder) => {
  builder.addCase(setActiveIma, (state, action) => ([...state, action.payload]));

  builder.addCase(updateIma, (state, action) => {
    const ima = action.payload;
    if (!ima) {
      return state;
    }

    return state.map((v: IMA) => (v.id === ima.id ? { ...ima, imaSigner: v.imaSigner } : v));
  });

  builder.addCase(setImaList, (state, action) => ([...action.payload]));

  builder.addCase(updateImaSigner, (state, action) => {
    const imaSigner = action.payload;
    if (!imaSigner) {
      return state;
    }

    const newState = [...state];
    const index = newState.findIndex(ima => ima.id === imaSigner.imaID);
    newState[index] = {
      ...newState[index],
      imaSigner,
    };

    return newState;
  });
});
