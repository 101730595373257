import axios from 'axios';
import { createAction } from '@reduxjs/toolkit';
import { responseListMapper } from '@qwealth/qcore';
import { AppThunkDispatch } from 'data/store';
import { errorHandler, REACT_APP_QWEALTH_API } from 'services/axiosService';
import { MyHousehold, MyLead } from '../models/MyEntity';

const SET_MY_LIST_LOADING_STATE = 'SET_MY_LIST_LOADING_STATE';
const PUT_MY_LIST = 'PUT_MY_LIST';

export const putMyList = createAction<Array<MyLead | MyHousehold>>(PUT_MY_LIST);
export const setMyListLoadingState = createAction<boolean>(SET_MY_LIST_LOADING_STATE);

export const loadMyList = () => async (dispatch: AppThunkDispatch, getState): Promise<void> => {
  if (getState().myList.isLoading) {
    return Promise.reject('Already loading My Clients list!');
  }
  await dispatch(setMyListLoadingState(true));
  return axios
    .get(`${REACT_APP_QWEALTH_API}/myList`)
    .then(response => responseListMapper<MyLead | MyHousehold>(response))
    .then(myList => {
      dispatch(putMyList(myList));
    })
    .catch(err => {
      dispatch(putMyList([]));
      errorHandler(dispatch, 'Could not load My List')(err);
    })
    .finally(() => dispatch(setMyListLoadingState(false)));
}
